<template>
  <div>
    <TopPanel
      back
      backText="Вернуться назад"
      @back-click="$router.push({ name: 'call' })"
    />
    <Block title="Настройки">
      <div v-if="isLoading"><Loader /></div>
      <div v-else class="mt-30">
        <div class="call-param" v-for="param in params" :key="param.id">
          <div class="call-param-department">
            <b-form-group label="Отдел" class="mb-0">
              <b-form-input v-model="param.title" label="Отдел" disabled />
            </b-form-group>
          </div>
          <div class="call-param-divider">
            <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.44995 0.292893C7.07246 -0.0976311 6.46042 -0.0976311 6.08292 0.292893C5.70543 0.683417 5.70543 1.31658 6.08292 1.70711L8.29931 4H0.966634C0.432777 4 0 4.44772 0 5C0 5.55228 0.432777 6 0.966634 6H8.29931L6.08292 8.29289C5.70543 8.68342 5.70543 9.31658 6.08292 9.70711C6.46042 10.0976 7.07246 10.0976 7.44995 9.70711L12 5L7.44995 0.292893Z" fill="#808BAC"/>
            </svg>
          </div>
          <div class="call-param-phones">
            <b-form-group label="Источник" class="mb-0">
              <b-form-tags
                v-model="param.phones"
                placeholder=""
                add-button-text=""
                add-button-variant="d-none"
                no-outer-focus
                :tag-validator="phoneValidator"
                invalid-tag-text="Допустимы только цифры длиной 11 символов"
                duplicate-tag-text="Такой номер уже существует"
                @input="save"
                />
            </b-form-group>
          </div>
        </div>
      </div>
    </Block>
  </div>
</template>

<script>
import NotifyMixin from '@/mixins/NotifyMixin'
import TopPanel from '@/components/TopPanel'

export default {
  name: 'Settings',
  mixins: [NotifyMixin],
  components: {
    TopPanel
  },
  data () {
    return {
      isLoading: true,
      params: [],
      showSuccessSaved: false
    }
  },
  async created () {
    this.params = await this.$api.query('call/settings')

    this.isLoading = false
  },
  methods: {
    phoneValidator (phone) {
      return /^(7[0-9]{10})$/.test(phone)
    },
    async save () {
      try {
        await this.$api.post('call/settings', { params: this.params })

        this.notifySuccess('Настройки', 'Настройки успешно сохранены')
      } catch (e) {
        this.notifyError('Ошибка', 'Не удалось сохранить настройки')
      }
    }
  }
}
</script>

<style>
.call-param .b-form-tag {
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #1F2641;
  background: transparent;
  padding: 0;
}
.call-param .b-form-tag button {
  line-height: 18px;
  font-size: 15px;
}
</style>
<style scoped>
.call-param {
  border: 1px solid #E1E1F1;
  border-radius: 5px;
  padding: 20px 25px;
  margin-bottom: 20px;
  display: flex;
}
.call-param-divider {
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 15px;
}
.call-param-divider svg {
  width: 12px;
  height: 10px;
}
.call-param-department {
  width: calc(50% - 16px);
}
.call-param-phones {
  width: calc(50% - 16px);
}
</style>
