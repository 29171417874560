<template>
  <div>
    <TopPanel
      actions
      :settings="[
        {
          url: '/call/settings',
          title: 'Настройки модуля',
          enabled: true
        }
      ]"
      @refresh-click="refreshData()"
    />
    <Block title="Пропущенные звонки">
      <MissedTable />
    </Block>
  </div>
</template>

<script>
import MissedTable from '@/components/call/MissedTable'
import TopPanel from '@/components/TopPanel'

export default {
  name: 'Missed',
  components: {
    MissedTable,
    TopPanel
  },
  methods: {
    refreshData () {
      this.$bus.$emit('refreshData')
    }
  }
}
</script>
