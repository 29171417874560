<template>
  <div>
    <b-table-simple class="table_plan mt-30">
      <b-thead>
        <b-tr>
          <b-th>
            <a href="#" id="call-table-filter-phone_number">Телефон <SpriteIcon icon="check" /></a>
            <b-popover target="call-table-filter-phone_number" @hidden="filterData()" triggers="focus" placement="bottom" custom-class="call-table-filter-dropdown">
              <b-input placeholder="+7 (000) 000-00-00" v-model="filter.phone_number" />
            </b-popover>
          </b-th>
          <b-th>Отдел</b-th>
          <b-th>
            <a href="#" id="call-table-filter-portal_number">Канал <SpriteIcon icon="check" /></a>
            <b-popover target="call-table-filter-portal_number" @hidden="filterData()" triggers="focus" placement="bottom" custom-class="call-table-filter-dropdown">
              <b-input placeholder="+7 (000) 000-00-00" v-model="filter.portal_number" />
            </b-popover>
          </b-th>
          <b-th>Дата</b-th>
          <b-th>Менеджер</b-th>
          <b-th>Клиент</b-th>
        </b-tr>
      </b-thead>
      <b-tbody v-if="!isLoading">
        <b-tr v-for="call in calls" :key="call.id">
          <b-td>
            <div class="call-missed"></div>
            <a :href="call.call_link" @click="makeCall($event, call.phone)" target="_blank" title="Нажмите, чтобы позвонить">{{ call.phone | phone }}</a>
          </b-td>
          <b-td>{{ call.department }}</b-td>
          <b-td>{{ call.portal_number | phone }}</b-td>
          <b-td>{{ call.date | datetime }}</b-td>
          <b-td>
            <Avatar :photo="call.responsible.avatar" :title="call.responsible.name" />
          </b-td>
          <b-td><a v-if="call.client.link_title" :href="call.client.link" target="_blank">{{ call.client.link_title }}</a></b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <div v-if="isLoading"><Loader></Loader></div>
  </div>
</template>

<script>
import B24 from '@ranx-ru/b24-async'
import { mapGetters } from 'vuex'
import Avatar from '@/components/ui/Avatar'

export default {
  name: 'MissedTable',
  components: {
    Avatar
  },
  data () {
    return {
      isLoading: true,
      calls: [],
      filter: {
        phone_number: '',
        portal_number: ''
      },
      lastFilter: {}
    }
  },
  computed: {
    ...mapGetters(['isBitrix24'])
  },
  mounted () {
    if (this.isBitrix24) {
      this.includeB24Script()
    }
  },
  async created () {
    this.fetchData()
    this.$bus.$on('refreshData', () => {
      this.fetchData()
    })
  },
  methods: {
    async fetchData () {
      this.isLoading = true

      this.calls = await this.$api.query('call/missed', { filter: this.filter })

      this.isLoading = false
    },
    filterData () {
      if (JSON.stringify(this.filter) === JSON.stringify(this.lastFilter)) {
        return
      }

      this.lastFilter = Object.assign({}, this.filter)
      this.fetchData()
    },
    async makeCall (e, phone) {
      e.preventDefault()

      if (this.isBitrix24) {
        await B24.sendMessage('imPhoneTo', { phone: phone })
      }
    },
    includeB24Script () {
      const b24Script = document.createElement('script')
      b24Script.setAttribute('src', '//api.bitrix24.com/api/v1/')
      document.head.appendChild(b24Script)
    }
  }
}
</script>

<style scoped>
.call-missed {
  background-color: var(--red-color);
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 15px;
  display: inline-block;
  margin-bottom: 2px;
}
</style>
