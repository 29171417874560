<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'CallIndex',
  async created () {
    await this.redirect()
  },
  watch: {
    async '$route' () {
      await this.redirect()
    }
  },
  methods: {
    async redirect () {
      if (this.$route.name === 'call') {
        const isSynced = await this.$api.query('call/settings/check-sync')
        if (!isSynced) {
          await this.$router.push({ name: 'call/sync' })
        } else {
          await this.$router.push({ name: 'call/missed' })
        }
      }
    }
  }
}
</script>
